<template>
  <CommonPage id="news-detail" title="News Details">
    <component :is="`theme_${themeConfig.landType}`" :themeConfig="themeConfig"
      :showDebug="showDebug" />
  </CommonPage>
</template>

<script>

const theme_1 = () => import('@/pages/modules/landing/theme_1_pc.vue') //代码切割
import CommonPage from './CommonPagePC.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'LandingMain',
  components: {
    theme_1,
    CommonPage
  },
  computed: {
    ...mapGetters({ //别名版
      themeConfig: "themeConfig",
      showDebug: "showDebug"
    })
  },
};
</script>
